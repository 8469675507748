import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";
import { useNavigate } from "react-router-dom";

const EditUser = () => {
  const { userId } = useParams();

  const [user, setUser] = useState({
    data: {},
    loading: true,
    error: null,
  });

  const [clientList, setClientList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [deviceTypeList, setDeviceTypeList] = useState({
    data: [],
    loading: true,
  });

  const [deviceList, setDeviceList] = useState({
    data: [],
    loading: true,
  });

  const [nameInput, setNameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  const { callApi } = useApi();
  const navigate = useNavigate();

  const handleResetUserForm = (_event) => {
    setNameInput(user.data.Name);
    setPhoneInput(user.data.Phone);
  };

  const resetDeviceAccessSelects = () => {
    setSelectedClient("");
    setSelectedDeviceType("");
    setSelectedDevice("");
    setDeviceTypeList({ data: [], loading: true });
    setDeviceList({ data: [], loading: true });
  };

  const handleSaveUser = async (event) => {
    event.preventDefault();
    try {
      const userUpdates = {
        Email: user.data.Email,
        Name: nameInput,
        Phone: phoneInput,
      };
      const options = {
        path: "/users",
        scope: "update:users",
        method: "PUT",
        body: JSON.stringify(userUpdates),
      };
      await callApi(options);
      setUser({
        ...user,
        data: {
          ...user.data,
          Name: nameInput,
          Phone: phoneInput,
        },
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleDeleteUser = async (event) => {
    event.preventDefault();
    try {
      const requestBody = { userId: user.data.userId };
      const options = {
        path: "/users",
        scope: "delete:users",
        method: "DELETE",
        body: JSON.stringify(requestBody),
      };
      await callApi(options);
      navigate(`/users`);
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleSaveUserDeviceAccess = async (event) => {
    event.preventDefault();
    if (selectedClient === "") {
      return;
    }

    const rawDeviceAccess = {
      clientId: selectedClient,
      deviceTypeId: selectedDeviceType,
      deviceId: selectedDevice,
    };
    // Filter out any keys with null values
    const requestBody = Object.fromEntries(
      Object.entries(rawDeviceAccess).filter(([_, v]) => !!v)
    );
    try {
      const options = {
        path: `/users/${userId}/device_access`,
        scope: "update:users:device_access",
        method: "PUT",
        body: JSON.stringify(requestBody),
      };

      const deviceAccess = await callApi(options).then((res) => res.json());
      setUser({
        ...user,
        data: {
          ...user.data,
          deviceAccess,
        },
      });
      resetDeviceAccessSelects();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleDeleteAccess = async (deviceAccessId, event) => {
    event.preventDefault();
    try {
      const options = {
        path: `/users/${userId}/device_access`,
        scope: "update:users:device_access",
        method: "DELETE",
        body: deviceAccessId,
      };
      const deviceAccess = await callApi(options).then((res) => res.json());
      setUser({
        ...user,
        data: {
          ...user.data,
          deviceAccess,
        },
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleNewClientSelected = (event) => {
    let client = event.currentTarget.value;
    setSelectedClient(client);
    setDeviceTypeList({
      data: [],
      loading: true,
    });
    if (client !== "") {
      fetchDeviceTypeList(client);
    }
  };

  const handleNewDeviceTypeSelected = (event) => {
    let deviceType = event.currentTarget.value;
    setSelectedDeviceType(deviceType);
    setDeviceList({
      data: [],
      loading: true,
    });
    if (deviceType !== "") {
      fetchDeviceList(deviceType);
    }
  };

  const fetchUser = async () => {
    try {
      const options = {
        path: `/users/${userId}`,
        scope: "read:users",
      };
      const fetchedUser = await callApi(options).then((res) => res.json());
      setUser({
        ...user,
        data: fetchedUser,
        error: null,
        loading: false,
      });
      setNameInput(fetchedUser.name);
      setPhoneInput(fetchedUser.phone);
    } catch (error) {
      errorLogger(error);
      setUser({
        ...user,
        error,
        loading: false,
      });
    }
  };

  const fetchClientList = async () => {
    try {
      const options = {
        path: "/clients",
        scope: "list:clients",
      };
      const fetchedClients = await callApi(options).then((res) => res.json());
      const clients = fetchedClients.map(({ clientId, clientName }) => ({
        clientId,
        clientName,
      }));
      setClientList({
        ...clientList,
        data: clients,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setClientList({
        ...clientList,
        error,
        loading: false,
      });
    }
  };

  const fetchDeviceTypeList = async (client) => {
    try {
      const options = {
        path: "/device_types",
        query: { clientId: client },
        scope: "list:device_types",
      };
      const res = await callApi(options);
      setDeviceTypeList({
        ...deviceTypeList,
        data: await res.json(),
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  const fetchDeviceList = async (deviceType) => {
    try {
      const options = {
        path: "/devices",
        query: { clientId: selectedClient, deviceTypeId: deviceType },
        scope: "list:devices",
      };
      const res = await callApi(options);
      setDeviceList({
        ...deviceList,
        data: await res.json(),
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientOptions = clientList.loading ? (
    <></>
  ) : (
    [
      <option value="" key="">
        Select client...
      </option>,
    ].concat(
      clientList.data.map(({ clientId, clientName }) => (
        <option value={clientId} key={clientId}>
          {clientName}
        </option>
      ))
    )
  );

  const deviceTypeOptions = deviceTypeList.loading ? (
    <></>
  ) : (
    [
      <option value="" key="">
        All Device Types
      </option>,
    ].concat(
      deviceTypeList.data.map((type) => (
        <option value={type.id} key={type.id}>
          {type.name}
        </option>
      ))
    )
  );

  const deviceOptions = deviceList.loading ? (
    <></>
  ) : (
    [
      <option value="" key="">
        All Devices
      </option>,
    ].concat(
      deviceList.data.map((device) => (
        <option value={device.id} key={device.id}>
          {device.eui.substring(device.eui.length - 4) +
            " \u2022 " +
            device.name}
        </option>
      ))
    )
  );

  return (
    <div className="EditUser page">
      <h2>EditUser {user.name}</h2>
      <form onSubmit={handleSaveUser}>
        <div>
          <label htmlFor="user-email">Email</label>
          <input
            id="user-email"
            type="email"
            defaultValue={user.data.email}
            disabled
          />
        </div>
        <div>
          <label htmlFor="user-name">Name</label>
          <input
            id="user-name"
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="user-phone">Phone</label>
          <input
            id="user-phone"
            type="tel"
            value={phoneInput}
            onChange={(e) => setPhoneInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="user-client">Client</label>
          <input
            id="user-client"
            type="text"
            defaultValue={user.data.clientName}
            disabled
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Reset" onClick={handleResetUserForm} />
        <input type="button" value="Delete" onClick={handleDeleteUser} />
      </form>
      <h2>Device Access</h2>
      {user.data?.deviceAccess &&
        user.data.deviceAccess.map((access) => (
          <div key={access.id}>
            {[access.client, access.deviceType, access.device]
              .filter((v) => v !== null)
              .join(" 🢒 ")}
            <button
              className="danger"
              onClick={(event) => handleDeleteAccess(access.id, event)}
            >
              Delete
            </button>
          </div>
        ))}
      <form onSubmit={handleSaveUserDeviceAccess}>
        <select value={selectedClient} onChange={handleNewClientSelected}>
          {clientOptions}
        </select>
        <select
          value={selectedDeviceType}
          onChange={handleNewDeviceTypeSelected}
        >
          {deviceTypeOptions}
        </select>
        <select
          value={selectedDevice}
          onChange={(e) => setSelectedDevice(e.currentTarget.value)}
        >
          {deviceOptions}
        </select>
        <input type="submit" value="Save" />
      </form>
    </div>
  );
};

export default EditUser;
