import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";

const Alerts = () => {
  const [alertList, setAlertList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [conditionInput, setConditionInput] = useState("");
  const [variableInput, setVariableInput] = useState("");
  const [thresholdInput, setThresholdInput] = useState("");
  const [hitsInput, setHitsInput] = useState("");
  const [resetDifferenceInput, setResetDifferenceInput] = useState("");
  const [recipientsInput, setRecipientsInput] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [activeInput, setActiveInput] = useState(true);

  const { callApi } = useApi();
  const navigate = useNavigate();

  const resetInputs = () => {
    setConditionInput("");
    setVariableInput("");
    setThresholdInput("");
    setHitsInput("");
    setResetDifferenceInput("");
    setRecipientsInput("");
    setMessageInput("");
    setActiveInput(true);
  };

  const handleCancel = () => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestBody = {
        condition: conditionInput,
        variable: variableInput,
        threshold: parseFloat(thresholdInput),
        hits: parseInt(hitsInput, 10),
        reset_difference: parseFloat(resetDifferenceInput),
        recipients: recipientsInput.split(",").map((email) => email.trim()),
        message: messageInput,
        active: activeInput,
      };
      const options = {
        path: "/alerts",
        scope: "create:alerts",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newAlert = await callApi(options).then((res) => res.json());
      const newList = [...alertList.data, newAlert];
      setAlertList({
        ...alertList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleEdit = (alert, _event) => {
    const alertId = encodeURIComponent(alert.alertId);
    navigate(`/edit_alert/${alertId}`);
  };

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const options = {
          path: "/alerts",
          scope: "list:alerts",
        };
        const alerts = await callApi(options).then((res) => res.json());
        setAlertList({
          ...alertList,
          data: alerts,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setAlertList({
          ...alertList,
          error,
          loading: false,
        });
      }
    };

    fetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertRows = alertList.loading ? (
    <tr>
      <td colSpan="7">Loading...</td>
    </tr>
  ) : (
    alertList.data.map((alert) => (
      <tr key={alert.message}>
        <td>{alert.condition}</td>
        <td>{alert.variable}</td>
        <td>{alert.threshold}</td>
        <td>{alert.hits}</td>
        <td>{alert.reset_difference}</td>
        <td>{alert.recipients.join(", ")}</td>
        <td>{alert.message}</td>
        <td>{alert.active ? "Active" : "Inactive"}</td>
        <td>
          <button onClick={(e) => handleEdit(alert, e)}>Edit</button>
        </td>
      </tr>
    ))
  );

  return (
    <div className="Alerts page">
      <h2>Alerts</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="alert-condition">Condition</label>
          <input
            id="alert-condition"
            type="text"
            value={conditionInput}
            onChange={(e) => setConditionInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-variable">Variable</label>
          <input
            id="alert-variable"
            type="text"
            value={variableInput}
            onChange={(e) => setVariableInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-threshold">Threshold</label>
          <input
            id="alert-threshold"
            type="number"
            value={thresholdInput}
            onChange={(e) => setThresholdInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-hits">Hits</label>
          <input
            id="alert-hits"
            type="number"
            value={hitsInput}
            onChange={(e) => setHitsInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-reset-difference">Reset Difference</label>
          <input
            id="alert-reset-difference"
            type="number"
            value={resetDifferenceInput}
            onChange={(e) => setResetDifferenceInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-recipients">Recipients (comma-separated)</label>
          <input
            id="alert-recipients"
            type="text"
            value={recipientsInput}
            onChange={(e) => setRecipientsInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-message">Message</label>
          <input
            id="alert-message"
            type="text"
            value={messageInput}
            onChange={(e) => setMessageInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="alert-active">Active</label>
          <input
            id="alert-active"
            type="checkbox"
            checked={activeInput}
            onChange={(e) => setActiveInput(e.currentTarget.checked)}
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Condition</th>
            <th>Variable</th>
            <th>Threshold</th>
            <th>Hits</th>
            <th>Reset Difference</th>
            <th>Recipients</th>
            <th>Message</th>
            <th>Active</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{alertRows}</tbody>
      </table>
    </div>
  );
};

export default Alerts;
