import "./Root.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useOutlet } from "react-router-dom";
import { useState } from "react";

function Root() {
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const userRoles = user?.cyf_roles;
  const isAdmin = userRoles && userRoles.includes("Admin");

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const outlet = useOutlet();

  return (
    <div>
      <div className="nav-container">
        <nav>
          <div
            className={`hamburger-menu ${isMenuOpen ? "change" : ""}`}
            onClick={toggleMenu}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "show" : ""}`}>
            {isAuthenticated ? (
              <>
                {isAdmin ? (
                  <>
                    <li>
                      <Link to="/alerts" onClick={toggleMenu}>
                        Alerts
                      </Link>
                    </li>
                    <li>
                      <Link to="/clients" onClick={toggleMenu}>
                        Clients
                      </Link>
                    </li>
                    <li>
                      <Link to="/users" onClick={toggleMenu}>
                        Users
                      </Link>
                    </li>
                    <li>
                      <Link to="/parameters" onClick={toggleMenu}>
                        Parameters
                      </Link>
                    </li>
                    <li>
                      <Link to="/device_types" onClick={toggleMenu}>
                        Device Types
                      </Link>
                    </li>
                    <li>
                      <Link to="/manufacturers" onClick={toggleMenu}>
                        Manufacturers
                      </Link>
                    </li>
                    <li>
                      <Link to="/device_models" onClick={toggleMenu}>
                        Device Models
                      </Link>
                    </li>
                    <li>
                      <Link to="/devices" onClick={toggleMenu}>
                        Devices
                      </Link>
                    </li>
                    <li>
                      <div className="menu-divider"></div>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                <li>
                  <button
                    id="logout"
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <li>
                <button id="login" onClick={() => loginWithRedirect()}>
                  Login
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
      {outlet || (
        <div className="page">
          <p>Welcome to CalmYourFarm Admin Portal</p>
          <p>Use the menu to choose the form you need</p>
        </div>
      )}
    </div>
  );
}

export default Root;
