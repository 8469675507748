import CalibrationFile from "./parameter_input/CalibrationFile";
import Dropdown from "./parameter_input/Dropdown";
import Number from "./parameter_input/Number";

const ParameterInput = ({ value, onChange, ...props }) => {
  switch (props.inputType) {
    case "number":
      return (
        <Number
          label={props.name}
          value={value}
          onChange={onChange}
          constraints={props.inputConstraints}
        />
      );
    case "dropdown":
      return (
        <Dropdown
          label={props.name}
          value={value}
          onChange={onChange}
          allowedValues={props.inputConstraints}
        />
      );
    case "calibration file":
      return (
        <CalibrationFile label={props.name} value={value} onChange={onChange} />
      );
    default:
      <span>Unsupported parameter type {props.inputType}</span>;
  }
};

export default ParameterInput;
